.sidenav {
  height: 100vh;
  width: 80px; /* Largura inicial */
  position: relative;
  float: left;
  top: 0;
  left: 0;
  background-color: #333;
  overflow-x: hidden;
  transition: width 0.3s;
}

.sidenav.open {
  width: 200px; /* Largura expandida */
}

.menuToggle {
  padding: 15px;
  color: #fff;
  cursor: pointer;
}

.userIcon {
  padding: 15px 20px;
  color: #fff;
}

.sidenav a {
  padding: 15px 20px;
  text-decoration: none;
  color: #fff;
  display: block;
}

.sidenav a:hover {
  background-color: #555;
}

.logout {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.logout a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 10px 0;
}

.logout a:hover {
  background-color: #555;
}

.itemMenuContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
}

.textMenuClose {

  display: none;

}