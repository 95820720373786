.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loginForm {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.formGroup {
    margin-bottom: 20px;
}


.loginForm label {
    display: block;
    margin-bottom: 5px;
}


.loginForm input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}


.loginForm button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}


.loginForm button:hover {
    background-color: #0056b3;
}
