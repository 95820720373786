.container {
  width: 100%;
  max-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.detalhes {
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}

.formContainer {
  width: 1100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;
}

.detalhes h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.detalhes label {
  display: block;
  margin-bottom: 10px;
}

.detalhes input[type="text"],
.detalhes textarea {
  width: 500px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.detalhes textarea {
  width: 800px;
  height: 120px;
  resize: none;
}

.detalhes button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  height: 50px;
  width: 100px;
  border-radius: 4px;
  cursor: pointer;
}

.detalhes button:hover {
  background-color: #0056b3;
}

.selectedTab {
  background-color: #0056b3;
  color: white;
}

.tab {
  display: flex;
  flex-direction: row;
  width: 700px;
  gap: 5px; 
}

.tab h3 {
  padding: 10px;
  border: 1px solid #c1c2c3;
  cursor: pointer;
  width: 200px;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}