.container {
    width: 100%;
    max-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.formContainer {
    text-align: center;
    flex-direction: column;
    justify-content: center;
}


.formContainer form {
    max-width: 500px;
    width: 300px;
}

.formContainer input {
    width: 100%;
}

.formContainer input {
    display: block;
    border-radius: 5px;
}

.formContainer button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
}


.formContainer button:hover {
    background-color: #0056b3;
}
.containerList {
    overflow: auto;
}

.descRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 420px;
    padding: 10px;
    border-radius: 5px;
}

.blocked {
    background-color: #aa2222 !important;
    color: white;
}

.descRow:nth-child(odd) {
    
    background-color: #ddd;
}

.title {
    color: #222;
    font-size: 18px;
    margin-bottom: 20px;
}