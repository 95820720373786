
.imageContainer {
    display: flex;
    flex-direction: column;
    width: 600px;
    padding: 10px;
    height: calc(100vh - 170px);
    overflow: auto;
}


.imgResponsiva {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}
